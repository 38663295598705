import { Order } from '../../../models/order/order'
import { PusherWrapper } from '../../../utils/pusherProvider'

export enum DashboardFilters {
  PostClosingQC = 'post_closing_qc',
  NeedsScanbacks = 'needs_scanbacks',
  Search = 'Search'
}

export type DashboardContentProps = {
  transactionTypeFlipper: boolean
  companyId: number
  searchParams: object
  changeSearchParams: (params: object) => void
  pusherWrapper: (pusherAppKey: string, pusherAppCluster: string) => PusherWrapper
  isRealTimeDashboardEnabled: boolean
  isSweepingEnabled: boolean
}

export type OrdersTableProps = {
  transactionTypeFlipper: boolean
  companyId: number
  searchParams: object
  changeSearchParams: (params: object) => void
  pusherWrapper: (pusherAppKey: string, pusherAppCluster: string) => PusherWrapper
  isRealTimeDashboardEnabled: boolean
  isSweepingEnabled: boolean
}

export type TrackingInfoProps = {
  order: Order
}

export type OwnerAvatarProps = {
  order: Order
}

export type AutoReminderNoteProps = {
  reminderSentAt: string | null
  tooltipTitle: string
}

export type StatusColumnTagProps = {
  reminderSentAt: string | null
  tooltipTitle: string
  tagClassName: string
  tagContent: string
}

export type ReportPostClosingErrorProps = {
  order: Order
}

export type PostClosingErrorFormValues = {
  reason: string[]
  explanation?: string
  notifyNotary: boolean
}

export type ApprovePostClosingProps = {
  order: Order
}

export type SorterProp = {
  order?: string
  field?: string
}

export type UserPresence = {
  id: number
  name: string
  initials: string
  photo_url: string
  timestamp: number
}
