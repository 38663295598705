import React, { useState, useEffect, FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { Row, Radio, Menu, Badge, Dropdown } from 'antd'
import { DashboardNavigationProps, MenuItemOnClickEvent } from './types'
import { Snapdocs } from '../../../hooks/api'
import { Requests, ReactQuery } from '../../../api'
import { get, camelCase, set } from 'lodash'
import { CaretUpDown } from '../../Shared/Icons/CaretUpDown/CaretUpDown'
import DashboardHelper from '../../../helpers/DashboardHelper/DashboardHelper'
import { NavigationFilters } from '../../../helpers/DashboardHelper/NavigationFilters'
import { NavigationFiltersText } from '../../../helpers/DashboardHelper/NavigationFiltersText'
import { ChannelEvents } from '../../../models/event/channelEvents'
import { OwnerFilters } from '../../../helpers/DashboardHelper/OwnerFilters'
import { useQuery } from '@tanstack/react-query'

export const DashboardNavigation: FC<DashboardNavigationProps> = ({
  ordersPage,
  companyId,
  searchParams,
  changeSearchParams,
  pusherWrapper
}): ReactElement => {
  const ordersFilter = DashboardHelper.selectedFilter(get(searchParams, 'filter', ''))
  const sideBarStatus = ordersPage ? ordersFilter : NavigationFilters.Notifications
  const teamId = get(searchParams, 'company_team_id') || ''
  const owner = get(searchParams, 'owner') || DashboardHelper.getValueFromCookie('last_owner') || OwnerFilters.Mine
  const [notificationOffset, setNotificationOffset] = useState(0)
  const isUpcomingFilterSelected = DashboardHelper.isUpcomingFilterSelected(ordersFilter, ordersPage)
  const lastUsedPreSigningFilter =
    DashboardHelper.getValueFromCookie('last_used_presigning_filter') || NavigationFilters.Presigning
  const { request: unreadCountRequest, response: unreadCountResponse } = Snapdocs.useResources(
    Requests.unreadNotificationsCount({ owner })
  )
  const dashboardCountsRequest = Requests.dashboardCounts({ owner, teamId, filter: ordersFilter })
  const { data: dashboardCountsData } = useQuery({
    queryKey: [dashboardCountsRequest.type, { owner, teamId, filter: ordersFilter }],
    queryFn: () => ReactQuery.fetchResources(dashboardCountsRequest)
  })
  const dashboardCounts = get(dashboardCountsData, 'resources')

  const { response: appConfig } = Snapdocs.useResources(Requests.appConfig())
  const { response: company } = Snapdocs.useResources(Requests.company({ id: companyId }))

  const filterByStatus = (e: MenuItemOnClickEvent) => {
    const selectedStatus = e.key.toString()
    if (ordersPage && selectedStatus === ordersFilter) {
      return
    }

    updateSearchParams(selectedStatus, owner, teamId)
  }

  const filterByOwner = (owner: string) => {
    updateSearchParams(ordersFilter, owner, teamId)
  }

  const updateSearchParams = (filter: string, owner: string, teamId: string) => {
    const newSearchParams = {}
    const sortBy = get(searchParams, ['q', 's'])

    set(newSearchParams, 'filter', filter)
    set(newSearchParams, 'owner', owner)
    set(newSearchParams, 'company_team_id', teamId)

    if (sortBy) {
      set(newSearchParams, ['q', 's'], sortBy)
    }

    changeSearchParams(newSearchParams)
  }

  const getNotificationOffset = (notificationCount: number) => {
    if (notificationCount > 99) {
      return 0
    } else if (notificationCount > 9) {
      return -2
    } else {
      return -5
    }
  }

  const upcomingFilterPopUpMenu = (
    <Menu selectedKeys={[sideBarStatus]}>
      <Menu.Item key={NavigationFilters.Presigning} onClick={filterByStatus}>
        {NavigationFiltersText.AllUpcoming}
        <span className="navigation-badge">
          <Badge overflowCount={DashboardHelper.overflowCount} count={dashboardCounts?.counters.presigning ?? 0} />
        </span>
      </Menu.Item>
      <Menu.Item key={NavigationFilters.PresigningToday} onClick={filterByStatus}>
        {NavigationFiltersText.PresigningToday}
        <span className="navigation-badge">
          <Badge overflowCount={DashboardHelper.overflowCount} count={dashboardCounts?.counters.presigningToday ?? 0} />
        </span>
      </Menu.Item>
      <Menu.Item key={NavigationFilters.PresigningTomorrow} onClick={filterByStatus}>
        {NavigationFiltersText.PresigningTomorrow}
        <span className="navigation-badge">
          <Badge
            overflowCount={DashboardHelper.overflowCount}
            count={dashboardCounts?.counters.presigningTomorrow ?? 0}
          />
        </span>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (Requests.hasLoaded(unreadCountRequest)) {
      setNotificationOffset(getNotificationOffset(unreadCountResponse.unreadCount))
    }
  }, [unreadCountRequest])

  useEffect(() => {
    if (!appConfig) return

    const pusherInstance = pusherWrapper(appConfig.pusherAppKey, appConfig.pusherAppCluster)
    pusherInstance.subscribeForEvent(appConfig.companyChannel, ChannelEvents.OrderNotificationAdded, () => {
      Snapdocs.dispatchReset('unreadNotificationsCount')
    })
    pusherInstance.subscribeForEvent(appConfig.companyChannel, ChannelEvents.OrderNotificationUpdated, () => {
      Snapdocs.dispatchReset('unreadNotificationsCount')
    })

    return () => pusherInstance.unsubscribeFromTheChannel(appConfig.companyChannel)
  }, [appConfig])

  return (
    <div data-test="navigation" className="nav sidebar orders-sidebar">
      <div className="heading">
        <Row>
          <h3>Orders</h3>
        </Row>
        <Row data-test="order-owner-btn-wrapper-row">
          <Radio.Group
            options={DashboardHelper.filterOptions}
            optionType="button"
            buttonStyle="solid"
            defaultValue={owner}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => filterByOwner(e.target.value)}
            data-test="order-owner-btn-wrapper"
          />
        </Row>
      </div>
      <Row className="navigation">
        <Menu selectedKeys={[sideBarStatus]} mode={'inline'}>
          <Menu.Item
            key={NavigationFilters.Notifications}
            onClick={() => (window.location.href = `/notifications?owner=${owner}`)}
            icon={
              <Badge
                size="small"
                count={unreadCountResponse?.unreadCount ?? 0}
                className="notification-badge"
                offset={[notificationOffset, 0]}
              >
                <Icons.Bell className="notifications-bell" />
              </Badge>
            }
            data-test="order-comments-notification"
          >
            {NavigationFiltersText.Notifications}
          </Menu.Item>
          <Menu.Divider />
          {(company?.automatedSweeping ?? false) && (
            <>
              <Menu.Item
                key={NavigationFilters.Pinned}
                onClick={filterByStatus}
                icon={
                  <Badge
                    overflowCount={DashboardHelper.overflowCount}
                    count={dashboardCounts?.counters.pinned ?? 0}
                    className="navigation-badge"
                  />
                }
                data-test="order-comments-notification"
              >
                {NavigationFiltersText.Pinned}
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item
            key={lastUsedPreSigningFilter}
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={DashboardHelper.overflowCount}
                count={get(dashboardCounts, ['counters', camelCase(lastUsedPreSigningFilter)], 0)}
                className="navigation-badge"
              />
            }
          >
            <Dropdown
              trigger={['click']}
              overlay={upcomingFilterPopUpMenu}
              disabled={!isUpcomingFilterSelected}
              placement="bottomCenter"
              overlayClassName="upcoming-filter-dropdown"
            >
              <span className={isUpcomingFilterSelected ? 'upcoming-filter-text' : ''}>
                {DashboardHelper.upcomingFilterText(lastUsedPreSigningFilter)}{' '}
                <CaretUpDown size={12} color={isUpcomingFilterSelected ? '#3E6BE0' : '#B8C4D5'} />
              </span>
            </Dropdown>
          </Menu.Item>
          <Menu.ItemGroup>
            <Menu.Item
              key={NavigationFilters.NeedsNotary}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.needsNotary ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NeedsNotary}
            </Menu.Item>
            <Menu.Item
              key={NavigationFilters.NeedsAppointmentConfirmation}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.needsAppointmentConfirmation ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NeedsAppointmentConfirmation}
            </Menu.Item>
            <Menu.Item
              key={NavigationFilters.NeedsDocuments}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.needsDocuments ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NeedsDocuments}
            </Menu.Item>
            <Menu.Item
              key={NavigationFilters.NeedsDocumentsDownloaded}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.needsDocumentsDownloaded ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NeedsDocumentsDownloaded}
            </Menu.Item>
            <Menu.Item
              key={NavigationFilters.NeedsRescheduling}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.needsRescheduling ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NeedsRescheduling}
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item
            key={NavigationFilters.DidNotSign}
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={DashboardHelper.overflowCount}
                count={dashboardCounts?.counters.didNotSign ?? 0}
                className="navigation-badge"
              />
            }
          >
            {NavigationFiltersText.DidNotSign}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key={NavigationFilters.OnHold}
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={DashboardHelper.overflowCount}
                count={dashboardCounts?.counters.onHold ?? 0}
                className="navigation-badge"
              />
            }
          >
            {NavigationFiltersText.OnHold}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key={NavigationFilters.NeedsSigningStatus}
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={DashboardHelper.overflowCount}
                count={dashboardCounts?.counters.needsCompletionStatus ?? 0}
                className="navigation-badge"
              />
            }
          >
            {NavigationFiltersText.NeedsSigningStatus}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key={NavigationFilters.SigningComplete}
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={DashboardHelper.overflowCount}
                count={dashboardCounts?.counters.anyPostClosingStatus ?? 0}
                className="navigation-badge"
              />
            }
          >
            {NavigationFiltersText.SigningComplete}
          </Menu.Item>
          <Menu.ItemGroup>
            <Menu.Item
              key={NavigationFilters.NeedsScanbacks}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.needsScanbacks ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NeedsScanbacks}
            </Menu.Item>
            {(company?.postClosingTools ?? false) && (
              <Menu.Item
                key={NavigationFilters.PostClosingQc}
                onClick={filterByStatus}
                icon={
                  <Badge
                    overflowCount={DashboardHelper.overflowCount}
                    count={dashboardCounts?.counters.postClosingQc ?? 0}
                    className="navigation-badge"
                  />
                }
              >
                {NavigationFiltersText.PostClosingQc}
              </Menu.Item>
            )}
            <Menu.Item
              key={NavigationFilters.NotYetShipped}
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={DashboardHelper.overflowCount}
                  count={dashboardCounts?.counters.notYetShipped ?? 0}
                  className="navigation-badge"
                />
              }
            >
              {NavigationFiltersText.NotYetShipped}
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item
            key={NavigationFilters.Closed}
            id="closed-orders"
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={DashboardHelper.overflowCount}
                count={dashboardCounts?.counters.closed ?? 0}
                className="navigation-badge"
              />
            }
          >
            {NavigationFiltersText.Closed}
          </Menu.Item>
          <Menu.Divider />
        </Menu>
      </Row>
    </div>
  )
}
