import React, { useState, useEffect, FC, ReactElement } from 'react'
import { Icons } from '../../Shared/Ant'
import { Row, Radio, Menu, Badge, Dropdown } from 'antd'
import { NavigationSkeleton } from './NavigationSkeleton'
import { getQueryParamsState } from '../../../utils/queryParams'
import { NavigationProps, MenuItemOnClickEvent } from './types'
import { Snapdocs } from '../../../hooks/api'
import { Requests, ReactQuery } from '../../../api'
import { get, camelCase } from 'lodash'
import { CaretUpDown } from '../../Shared/Icons/CaretUpDown/CaretUpDown'
import DashboardHelper from '../../../helpers/DashboardHelper/DashboardHelper'
import { UpcomingFilter } from '../../../helpers/DashboardHelper/UpcomingFilter'
import { UpcomingFilterText } from '../../../helpers/DashboardHelper/UpcomingFilterText'

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Mine', value: 'mine' }
]

const getValueFromCookie = (cookieName: string): string | null => {
  const pattern = new RegExp(`${cookieName}=(\\w+)`)
  const match = document.cookie.match(pattern)
  return match && match[1]
}

const selectedFilter = (filterParam: string): string => {
  if (filterParam) {
    return filterParam
  } else {
    return getValueFromCookie('last_used_filter') || UpcomingFilter.Presigning
  }
}

export const Navigation: FC<NavigationProps> = ({ ordersPage, companyId }): ReactElement => {
  const overflowCount = 999
  const params = getQueryParamsState()
  const ordersFilter = selectedFilter(params.filter)
  const sideBarStatus = ordersPage ? ordersFilter : 'notifications'
  const teamId = params.company_team_id || ''
  const owner = params.owner || getValueFromCookie('last_owner') || 'mine'
  const [notificationOffset, setNotificationOffset] = useState(0)
  const isUpcomingFilterSelected = DashboardHelper.isUpcomingFilterSelected(ordersFilter, ordersPage)
  const lastUsedPreSigningFilter = getValueFromCookie('last_used_presigning_filter') || UpcomingFilter.Presigning
  const { request: unreadCountRequest, response: unreadCountResponse } = Snapdocs.useResources(
    Requests.unreadNotificationsCount({ owner })
  )
  const { request: dashboardCountsRequest, response: dashboardCountsResponse } = Snapdocs.useResources(
    Requests.dashboardCounts({ owner, teamId, filter: ordersFilter })
  )
  const { company, companyLoading } = ReactQuery.useCompanyRequest(companyId)

  const filterByStatus = (e: MenuItemOnClickEvent) => {
    const selectedStatus = e.key.toString()
    if (ordersPage && selectedStatus === ordersFilter) {
      return
    }

    window.location.href = `${
      ordersPage ? '' : '/orders'
    }?filter=${selectedStatus}&owner=${owner}&company_team_id=${teamId}`
  }

  const filterByOwner = (owner: string) => {
    if (ordersPage) {
      window.location.search = `?filter=${sideBarStatus}&owner=${owner}&company_team_id=${teamId}`
    }
    window.location.search = `?owner=${owner}`
  }

  const getNotificationOffset = (notificationCount: number) => {
    if (notificationCount > 99) {
      return 0
    } else if (notificationCount > 9) {
      return -2
    } else {
      return -5
    }
  }

  const upcomingFilterPopUpMenu = (
    <Menu selectedKeys={[sideBarStatus]}>
      <Menu.Item key={UpcomingFilter.Presigning} onClick={filterByStatus}>
        {UpcomingFilterText.AllUpcoming}
        <span className="navigation-badge">
          <Badge overflowCount={overflowCount} count={get(dashboardCountsResponse, ['counters', 'presigning'])} />
        </span>
      </Menu.Item>
      <Menu.Item key={UpcomingFilter.PresigningToday} onClick={filterByStatus}>
        {UpcomingFilterText.PresigningToday}
        <span className="navigation-badge">
          <Badge overflowCount={overflowCount} count={get(dashboardCountsResponse, ['counters', 'presigningToday'])} />
        </span>
      </Menu.Item>
      <Menu.Item key={UpcomingFilter.PresigningTomorrow} onClick={filterByStatus}>
        {UpcomingFilterText.PresigningTomorrow}
        <span className="navigation-badge">
          <Badge
            overflowCount={overflowCount}
            count={get(dashboardCountsResponse, ['counters', 'presigningTomorrow'])}
          />
        </span>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (Requests.hasLoaded(unreadCountRequest)) {
      setNotificationOffset(getNotificationOffset(unreadCountResponse.unreadCount))
    }
  }, [unreadCountRequest])

  if (Requests.isLoading(dashboardCountsRequest) || companyLoading) {
    return (
      <div data-test="navigation-skeleton" className="nav sidebar orders-sidebar">
        <NavigationSkeleton />
      </div>
    )
  }

  return (
    <div data-test="navigation" className="nav sidebar orders-sidebar">
      <div className="heading">
        <Row>
          <h3>Orders</h3>
        </Row>
        <Row>
          <Radio.Group
            options={filterOptions}
            optionType="button"
            buttonStyle="solid"
            defaultValue={owner}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => filterByOwner(e.target.value)}
          />
        </Row>
      </div>
      <Row className="navigation">
        <Menu selectedKeys={[sideBarStatus]} mode={'inline'}>
          <Menu.Item
            key="notifications"
            onClick={() => (window.location.href = `/notifications?owner=${owner}`)}
            icon={
              <Badge
                size="small"
                overflowCount={99}
                count={get(unreadCountResponse, 'unreadCount', 0)}
                className="notification-badge"
                offset={[notificationOffset, 0]}
              >
                <Icons.Bell className="notifications-bell" />
              </Badge>
            }
          >
            Notifications
          </Menu.Item>
          <Menu.Divider />
          {company.automatedSweeping && (
            <>
              <Menu.Item
                key="pinned"
                onClick={filterByStatus}
                icon={
                  <Badge
                    overflowCount={overflowCount}
                    count={dashboardCountsResponse.counters.pinned}
                    className="navigation-badge"
                  />
                }
              >
                Pinned
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item
            key={lastUsedPreSigningFilter}
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={overflowCount}
                count={get(dashboardCountsResponse, ['counters', camelCase(lastUsedPreSigningFilter)])}
                className="navigation-badge"
              />
            }
          >
            <Dropdown
              trigger={['click']}
              overlay={upcomingFilterPopUpMenu}
              disabled={!isUpcomingFilterSelected}
              placement="bottomCenter"
              overlayClassName="upcoming-filter-dropdown"
            >
              <span className={isUpcomingFilterSelected ? 'upcoming-filter-text' : ''}>
                {DashboardHelper.upcomingFilterText(lastUsedPreSigningFilter)}{' '}
                <CaretUpDown size={12} color={isUpcomingFilterSelected ? '#3E6BE0' : '#B8C4D5'} />
              </span>
            </Dropdown>
          </Menu.Item>
          <Menu.ItemGroup>
            <Menu.Item
              key="needs_notary"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.needsNotary}
                  className="navigation-badge"
                />
              }
            >
              Needs notary
            </Menu.Item>
            <Menu.Item
              key="needs_appointment_confirmation"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.needsAppointmentConfirmation}
                  className="navigation-badge"
                />
              }
            >
              Needs appt confirmation
            </Menu.Item>
            <Menu.Item
              key="needs_documents"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.needsDocuments}
                  className="navigation-badge"
                />
              }
            >
              Needs documents
            </Menu.Item>
            <Menu.Item
              key="needs_documents_downloaded"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.needsDocumentsDownloaded}
                  className="navigation-badge"
                />
              }
            >
              Needs docs downloaded
            </Menu.Item>
            <Menu.Item
              key="needs_rescheduling"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.needsRescheduling}
                  className="navigation-badge"
                />
              }
            >
              Needs reschedule
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item
            key="did_not_sign"
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={overflowCount}
                count={dashboardCountsResponse.counters.didNotSign}
                className="navigation-badge"
              />
            }
          >
            Did not sign
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="on_hold"
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={overflowCount}
                count={dashboardCountsResponse.counters.onHold}
                className="navigation-badge"
              />
            }
          >
            On hold
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="needs_completion_status"
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={overflowCount}
                count={dashboardCountsResponse.counters.needsCompletionStatus}
                className="navigation-badge"
              />
            }
          >
            Needs signing status
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="any_post_closing_status"
            onClick={filterByStatus}
            id="completed-orders"
            icon={
              <Badge
                overflowCount={overflowCount}
                count={dashboardCountsResponse.counters.anyPostClosingStatus}
                className="navigation-badge"
              />
            }
          >
            Signing complete
          </Menu.Item>
          <Menu.ItemGroup>
            <Menu.Item
              key="needs_scanbacks"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.needsScanbacks}
                  className="navigation-badge"
                />
              }
            >
              Needs scanbacks
            </Menu.Item>
            {company.postClosingTools && (
              <Menu.Item
                key="post_closing_qc"
                onClick={filterByStatus}
                icon={
                  <Badge
                    overflowCount={overflowCount}
                    count={dashboardCountsResponse.counters.postClosingQc}
                    className="navigation-badge"
                  />
                }
              >
                Post-closing QC
              </Menu.Item>
            )}
            <Menu.Item
              key="not_yet_shipped"
              onClick={filterByStatus}
              icon={
                <Badge
                  overflowCount={overflowCount}
                  count={dashboardCountsResponse.counters.notYetShipped}
                  className="navigation-badge"
                />
              }
            >
              Not yet shipped
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item
            key="closed"
            id="closed-orders"
            onClick={filterByStatus}
            icon={
              <Badge
                overflowCount={overflowCount}
                count={dashboardCountsResponse.counters.closed}
                className="navigation-badge"
              />
            }
          >
            Closed
          </Menu.Item>
          <Menu.Divider />
        </Menu>
      </Row>
    </div>
  )
}
