import React, { FC, ReactElement } from 'react'
import { Avatar, Col } from 'antd'
import { ClientAndCompanyNameProps } from './types'

export const ClientAndCompanyName: FC<ClientAndCompanyNameProps> = ({
  notification,
  showClientNameInNotifications
}): ReactElement => {
  return (
    <Col flex="230px">
      <>
        {notification.companyName && (
          <div>
            <Avatar
              size="small"
              shape="square"
              className="company-favicon"
              style={{ margin: '0 15px 0 0', width: '18px', height: '18px' }}
              src={notification.companyFaviconUrl}
            />
            <span>{notification.companyName}</span>
          </div>
        )}
        {showClientNameInNotifications && (
          <div style={{ marginTop: '2px' }}>
            <span className="client-name">{notification.clientName}</span>
          </div>
        )}
      </>
    </Col>
  )
}
