import React from 'react'
import { Avatar, Row, Col } from 'antd'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'
import { Order } from '../../../../../models/order/order'
import './styles.modules.scss'

export const CompanyColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Company',
    dataIndex: 'companyName',
    defaultSortOrder: defaultSortOrder('companyName', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'companyName',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column company-column',
    render: (companyName: string, order: Order) => {
      return (
        <Row>
          <Col span={4}>
            <Avatar
              size="small"
              shape="square"
              style={{ margin: '0 15px 0 0', width: '18px', height: '18px' }}
              src={order.companyFaviconUrl}
            >
              {!order.companyFaviconUrl && companyName.charAt(0).toUpperCase()}
            </Avatar>
          </Col>
          <Col span={20}>{companyName}</Col>
        </Row>
      )
    }
  }
}
