export enum NavigationFilters {
  Notifications = 'notifications',
  Pinned = 'pinned',
  Presigning = 'presigning',
  PresigningToday = 'presigning_today',
  PresigningTomorrow = 'presigning_tomorrow',
  NeedsNotary = 'needs_notary',
  NeedsAppointmentConfirmation = 'needs_appointment_confirmation',
  NeedsDocuments = 'needs_documents',
  NeedsDocumentsDownloaded = 'needs_documents_downloaded',
  NeedsRescheduling = 'needs_rescheduling',
  DidNotSign = 'did_not_sign',
  OnHold = 'on_hold',
  NeedsSigningStatus = 'needs_completion_status',
  SigningComplete = 'any_post_closing_status',
  NeedsScanbacks = 'needs_scanbacks',
  PostClosingQc = 'post_closing_qc',
  NotYetShipped = 'not_yet_shipped',
  Closed = 'closed'
}
