import { Modal } from '../../Shared/Ant'
import React, { FC, ReactElement, useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ClientProductFormProps, Product } from '../types'
import { ProductFormBody } from '../ProductFormBody/ProductFormBody'
import { apiCall } from '../../../api/apiCall'
import { Requests } from '../../../api/requests'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'
import { Provider } from 'react-redux'
import { store, queryClient } from '../../../store'
import '../../Shared/Ant/App.less'

export const AddClientProduct: FC<ClientProductFormProps> = ({
  allowStateSpecificPricing,
  companyId,
  product,
  preferredReturnChoice,
  client
}): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const saveProduct = (
    values: Product,
    showDocumentChecklist: boolean,
    hasSpecialInstructions: boolean,
    showStateSpecificPricing: boolean
  ) => {
    values.clientId = client.id

    const params = {
      product: values,
      documentChecklistCheckbox: showDocumentChecklist,
      clearSpecialInstructions: !hasSpecialInstructions,
      hasStateSpecificPricing: showStateSpecificPricing
    }

    apiCall
      .post(Requests.products(params).path, {
        ...camelCaseToSnakeCase(params)
      })
      .then(() => {
        setIsModalVisible(false)
        window.location.reload()
      })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <a className="btn btn-sm btn-info btn-panel-heading" onClick={showModal}>
          Add product
        </a>

        <Modal
          title={'Add product for ' + client.company_name}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="product-form"
          width={700}
        >
          <ProductFormBody
            allowStateSpecificPricing={allowStateSpecificPricing}
            companyId={companyId}
            product={product}
            submitBtnText={'Create Product'}
            returnChoices={[]}
            preferredReturnChoice={preferredReturnChoice}
            fromOrderForm={false}
            saveProduct={saveProduct}
          />
        </Modal>
      </Provider>
    </QueryClientProvider>
  )
}
