import React, { FC, ReactElement } from 'react'
import { StatusColumnTagProps } from '../../types'
import { Tooltip, Tag } from 'antd'
import moment from 'moment-timezone'

export const StatusColumnTag: FC<StatusColumnTagProps> = ({
  reminderSentAt,
  tooltipTitle,
  tagClassName,
  tagContent
}): ReactElement => {
  const reminderSentAtFormatted = (reminderSentAt: string) => {
    const dateTime = moment.utc(reminderSentAt)

    if (moment.utc(reminderSentAt).isSame(moment.utc(), 'day')) {
      return dateTime.format('h:mm a')
    }

    return dateTime.format('M/D/YY h:mm a')
  }

  const tagElement = <Tag className={tagClassName}>{tagContent}</Tag>
  const tooltipText = `${tooltipTitle}${reminderSentAt ? ` at ${reminderSentAtFormatted(reminderSentAt)}` : ''}.`

  return <Tooltip title={tooltipText}>{tagElement}</Tooltip>
}
