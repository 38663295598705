import React, { FC, ReactElement } from 'react'
import { Icons } from '../Ant'
import { Button, Col, Form, Row, Select, Space, Input, DatePicker } from 'antd'
import { get } from 'lodash'
import { BoardFilterFormProps } from './types'
import { subStringSearch } from '../../../utils/matchStrings'
import './styles.modules.scss'
import moment from 'moment'
import { camelCaseToSnakeCase } from '../../../utils/camelCaseToSnakeCase'

export const BoardFilterForm: FC<BoardFilterFormProps> = ({
  filters,
  addFilter,
  removeFilter,
  currentFilter,
  index,
  disabled,
  searchOptions,
  defaultFilter
}): ReactElement => {
  const formattedSearchOptions = camelCaseToSnakeCase(searchOptions)

  const parseDate = (date: string | null) => {
    const parsedDate = moment(date, 'MM/DD/YY')
    return parsedDate.isValid() ? parsedDate : moment()
  }

  return (
    <Row className="board-filter-form" role="row">
      <Space>
        <Col>
          <Form.Item
            className="select-form"
            name={['options', index]}
            initialValue={currentFilter !== '' ? currentFilter : defaultFilter}
          >
            <Select disabled={disabled} data-test="select">
              {Object.keys(formattedSearchOptions)
                .filter((option) => currentFilter === option || !get(filters, option))
                .map((value, key) => (
                  <Select.Option data-test="select-option" value={value} key={key}>
                    {get(formattedSearchOptions, value)}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            className="input-form"
            name={['values', index]}
            valuePropName={subStringSearch(currentFilter, 'date') ? 'date' : 'value'}
            initialValue={
              subStringSearch(currentFilter, 'date')
                ? parseDate(get(filters, currentFilter))
                : get(filters, currentFilter, '')
            }
          >
            {subStringSearch(currentFilter, 'date') ? (
              <DatePicker className="date-input" format="MM/DD/YY" />
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Space>
              {disabled && (
                <Button
                  title="Remove criteria"
                  type="text"
                  icon={<Icons.Delete />}
                  onClick={() => removeFilter(currentFilter)}
                />
              )}
              {!disabled && (
                <Button data-testid="order-filter-search-btn" htmlType="submit">
                  Search
                </Button>
              )}
              {!disabled && currentFilter !== '' && get(filters, currentFilter) !== undefined && (
                <Button title="Add search criteria" onClick={() => addFilter()}>
                  +
                </Button>
              )}
            </Space>
          </Form.Item>
        </Col>
      </Space>
    </Row>
  )
}
