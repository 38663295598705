export enum NavigationFiltersText {
  Notifications = 'Notifications',
  Pinned = 'Pinned',
  AllUpcoming = 'All upcoming',
  PresigningToday = 'Signing today',
  PresigningTomorrow = 'Signing tomorrow',
  NeedsNotary = 'Needs notary',
  NeedsAppointmentConfirmation = 'Needs appt confirmation',
  NeedsDocuments = 'Needs documents',
  NeedsDocumentsDownloaded = 'Needs docs downloaded',
  NeedsRescheduling = 'Needs reschedule',
  DidNotSign = 'Did not sign',
  OnHold = 'On hold',
  NeedsSigningStatus = 'Needs signing status',
  SigningComplete = 'Signing complete',
  NeedsScanbacks = 'Needs scanbacks',
  PostClosingQc = 'Post-closing QC',
  NotYetShipped = 'Not yet shipped',
  Closed = 'Closed'
}
